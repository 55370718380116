.NavbarContainer {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 10;
  justify-content: space-between;
  /* background-color: #fff; */
  padding: 0.6rem;
}

.NavbarOnTop {
  padding: 0.8rem;
}

/* nav links - start */
.navItemsWrapper {
  position: relative;
  display: block;
  padding: 10px 0;
}

.navItemsWrapper:active {
  transform: translateY(0.1rem);
  transition: transform 0.01s ease;
}

/* nav links - end */


/* login button - start */
.loginButton {
  background-color: var(--background-0);
  padding: 0.6rem 1.5rem;
  border-radius: 5rem;
  color: var(--purple-2);
  font-size: 1rem;
  font-weight: bold;
}

.loginButton:active {
  transform: translateY(0.1rem);
  transition: transform 0.01s ease;
}

.loginButton:hover img {
  opacity: 1!important;
  left: 50%!important;
}
/* login button - end */