.App {
  font-family: var(--Roboto);
  font-weight: 400;

  color: var(--text-main-0);
  background-color: var(--background-color0);
}

.Transition-1 {
  transition: all 500ms cubic-bezier(0.67, 0.01, 0.34, 0.99);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  display: block;
  object-fit: contain;
  ;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

::selection {
  background-color: var(--purple-1) !important;
  color: white !important;
}

#root {
  --Roboto: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


/* start scrollbar */
::-webkit-scrollbar {
  /* width: 0.875rem; */
  width: 1rem;
  height: 1.125rem;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  height: 0.375rem;
  border: 0.25rem solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 0.438rem;
  border-radius: 0.5rem;
  background-color: #00c2b3;
  -webkit-box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
    inset 1px 1px 0px rgb(0 0 0 / 5%);
  box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
    inset 1px 1px 0px rgb(0 0 0 / 5%);
}

/* fonts */
/* roboto regular */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

/* roboto light */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("./assets/fonts/Roboto-Light.ttf") format("truetype");
}

/* roboto bold */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}

.pdf-view {
  display: flex;
  flex: 1 1 1;
  height: 780px;
  border-radius: .4rem;
}