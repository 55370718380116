.dark-mode {
  /* black: Textos | dev: .text-dark (mas já vem por padrão) */
  --background-0: #110f17; /*    0% */
  --background-1: #29272e; /* + 10% */
  --background-2: #413f45; /* + 20% */

  /* text-color: Fundos e textos |  dev: .text-light */
  --text-main-0: #aaa8a8; /* - 20%  */
  --text-main-1: #d4d2d2; /*    0% */
  --text-main-2: #fbfbfb; /* + 80% */

  /* reverse text color*/
  --text-reverse-0: #232333; /*    0% */
  --text-reverse-1: #393947; /* + 10% */
  --text-reverse-2: #4f4f5c; /* + 20% */

  /* purple: | dev: .text-primary */
  --purple-0: #300078; /* - 20% */
  --purple-1: #3c0096; /*    0% */
  --purple-2: #6333ab; /* + 20% */

  /* blue: pode ser usado em botões de avançar e confirmações. | dev: .text-secondary */
  --blue-0: #009b8f; /* - 20% */
  --blue-1: #00c2b3; /*    0% */
  --blue-2: #33cec2; /* + 20% */

  /* red: Botão de cancelar, voltar e alertas | dev: .text-danger */
  --red: #f8333c;

  /* yellow: Alertas | dev: .text-warning */
  --yellow: #ffcc0c;

  /* image contrast config  */
  /* destinado a alterar o constraste de imagens com cores fixas, como branco */
  /* por que usar isso? imagens brancas em fundos brancos no white-mode pode ser um problema */
  /* a config de constraste vai servir para cuidar disso conforme o tema aplicado */
  --image-brightness: 1;
}

.white-mode {
  /* black: Textos | dev: .text-dark (mas já vem por padrão) */
  --background-0: #f7f7ff; /*    0% */
  --background-1: #f2f1f6; /*   ??% */
  --background-2: #dedde2; /*   ??% */

  /* text-color: Fundos e textos |  dev: .text-light */
  --text-main-0: #232333; /*    0% */
  --text-main-1: #393947; /* + 10% */
  --text-main-2: #4f4f5c; /* + 20% */
  
  /* reverse text color*/
  --text-reverse-0: #aaa8a8; /* - 20%  */
  --text-reverse-1: #d4d2d2; /*    0% */
  --text-reverse-2: #fbfbfb; /* + 80% */

  /* purple: | dev: .text-primary */
  --purple-0: #300078; /* - 20% */
  --purple-1: #3c0096; /*    0% */
  --purple-2: #6333ab; /* + 20% */

  /* blue: pode ser usado em botões de avançar e confirmações. | dev: .text-secondary */
  --blue-0: #009b8f; /* - 20% */
  --blue-1: #00c2b3; /*    0% */
  --blue-2: #33cec2; /* + 20% */

  /* red: Botão de cancelar, voltar e alertas | dev: .text-danger */
  --red: #f8333c;

  /* yellow: Alertas | dev: .text-warning */
  --yellow: #ffcc0c;

  /* image brightness config  */
  /* destinado a alterar o constraste de imagens com cores fixas, como branco */
  /* por que usar isso? imagens brancas em fundos brancos no white-mode pode ser um problema */
  /* a config de constraste vai servir para cuidar disso conforme o tema aplicado */
  --image-brightness: 0.3;
}

@media screen and (max-width: 800px), screen and (max-height: 500px) {
  #root {
    font-size: 14px;
  }
}


.MuiFormLabel-root.Mui-focused {
  color: var(--blue-1) !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--blue-1) !important;
}