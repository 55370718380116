.Button {
  background-color: var(--blue-1);
  padding: 1rem 2rem;
  border-radius: 5rem;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.Button:active {
  transform: translateY(0.1rem);
  transition: transform 0.01s ease;
}

.Button:hover img {
  opacity: 1!important;
  left: 50%!important;
}